import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import {
  browserProfilingIntegration,
  browserTracingIntegration,
  replayIntegration,
  init as sentryInit,
} from "@sentry/remix";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

import { trimTrailingSlash } from "~/utils/strings";

const sentryDsn = trimTrailingSlash(window?.ENV?.SENTRY_DSN || "");
const sentryHostingEnv = window?.ENV?.SENTRY_HOSTING_ENV;

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker.getRegistrations().then(function (registrations) {
//     for (const registration of registrations) {
//       if (registration.active?.scriptURL == "https://powr-connect.shop/sw.js") {
//         registration.unregister();
//         if (typeof document !== "undefined") {
//           window.location.reload();
//         }
//       }
//     }
//   });
// }

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
}

sentryInit({
  dsn: sentryDsn,
  environment: sentryHostingEnv || "development",
  initialScope: { tags: { environment: sentryHostingEnv } },
  beforeSend(event) {
    if (event.request?.url) {
      const url = new URL(event.request.url);
      if (url.protocol === "chrome-extension:" || url.protocol === "moz-extension:") {
        // This error is from a browser extension, ignore it
        return null;
      }
    }
    return event;
  },
  integrations: [
    browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    browserProfilingIntegration(),
    replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

startTransition(() => {
  // clearBrowserExtensionInjectionsBeforeHydration();

  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});

function _clearBrowserExtensionInjectionsBeforeHydration() {
  document
    .querySelectorAll(
      [
        "html > *:not(body, head)",
        'script[src*="extension://"]',
        'link[href*="extension://"]',
      ].join(", ")
    )
    .forEach((s) => {
      s.parentNode?.removeChild(s);
    });

  const $targets = {
    html: {
      $elm: document.querySelector("html")!,
      allowedAttributes: ["lang", "dir", "class"],
    },
    head: {
      $elm: document.querySelector("head")!,
      allowedAttributes: [""],
    },
    body: {
      $elm: document.querySelector("body")!,
      allowedAttributes: ["class"],
    },
  };

  Object.entries($targets).forEach(([_targetName, target]) => {
    target.$elm.getAttributeNames().forEach((attr) => {
      if (!target.allowedAttributes.includes(attr)) {
        target.$elm.removeAttribute(attr);
      }
    });
  });
}
